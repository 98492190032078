import React from 'react';

export default function ProgressStep(props) {
  const { steps } = props;

  return (
    <div className="steps" style={{ width: '100%' }}>
      <ul className="progress-indicator">
        {steps.map(step => (
          <li key={step.label} className={step.completed ? 'completed' : ''}>
            <span className="bubble" />
            {step.label}
          </li>
        ))}
      </ul>
    </div>
  );
}
