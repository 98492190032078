import React from 'react';
import { Flex, Field, Card, Heading, Input, Button, Loader } from 'rimble-ui';
import CopyURLField from './CopyURLField';
import Terms from './Terms';

export default function BuyerView(props) {
  const { manifest, escrow, status, loading, onSendPayment, onBuyerConfirm, hasSeller } = props;
  return (
    <Card marginTop={25}>
      <Heading>You are the Buyer</Heading>
      <Flex height={25} />
      <Terms manifest={manifest} escrow={escrow} />
      <Flex height={25} />
      {hasSeller && (
        <Field label="Pay Amount" width={1}>
          <Input type="text" value={`${manifest.amount} ${manifest.token}`} required readOnly />
        </Field>
      )}
      {status === 'OfferCreated' && !hasSeller && (
        <>
          <CopyURLField url={window.location.href} />
          <Button disabled type="submit" width={1}>
            Waiting for the Seller...
          </Button>
        </>
      )}
      {status === 'OfferCreated' && hasSeller && (
        <Button disabled={loading} type="submit" width={1} onClick={onSendPayment}>
          {!loading ? 'Send Payment' : <Loader color="white" />}
        </Button>
      )}
      {status === 'PaymentConfirmed' && (
        <Button disabled type="submit" width={1}>
          Waiting for the Seller...
        </Button>
      )}
      {status === 'SellerConfirmed' && (
        <Button disabled={loading} type="submit" width={1} onClick={onBuyerConfirm}>
          {!loading ? 'Confirm Receipt' : <Loader color="white" />}
        </Button>
      )}
      {status === 'BuyerConfirmed' && (
        <Button disabled type="submit" width={1}>
          Waiting for the Seller...
        </Button>
      )}
      {status === 'OfferFinalized' && (
        <Button disabled type="submit" width={1}>
          Offer Finalized
        </Button>
      )}
    </Card>
  );
}
