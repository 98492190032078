import React from 'react';
import { Table, EthAddress } from 'rimble-ui';
import { NULL_ADDRESS } from '../constants/addresses';

export default function Terms(props) {
  const { manifest, escrow } = props;

  return (
    <>
      <Table mb={4}>
        <thead>
          <tr>
            <th>Parties</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {escrow.seller !== NULL_ADDRESS && (
            <tr>
              <td>
                <b>Seller</b>
              </td>
              <td>
                <EthAddress address={escrow.seller} />
              </td>
            </tr>
          )}
          {escrow.buyer !== NULL_ADDRESS && (
            <tr>
              <td>
                <b>Buyer</b>
              </td>
              <td>
                <EthAddress address={escrow.buyer} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Table>
        <thead>
          <tr>
            <th>Terms</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Title</b>
            </td>
            <td>{manifest.title}</td>
          </tr>
          <tr>
            <td>
              <b>Description</b>
            </td>
            <td>{manifest.description}</td>
          </tr>
          <tr>
            <td>
              <b>Amount</b>
            </td>
            <td>
              {manifest.amount} {manifest.token}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
