import React from 'react';
import { Flex, Card, Heading, Button, Loader } from 'rimble-ui';
import CopyURLField from './CopyURLField';
import Terms from './Terms';

export default function SellerView(props) {
  const { manifest, escrow, status, loading, onSellerInitialize, onSellerConfirm, onDisburse, hasSeller } = props;
  return (
    <Card marginTop={25}>
      {hasSeller && <Heading>You are the Seller</Heading>}
      <Flex height={25} />
      <Terms manifest={manifest} escrow={escrow} />
      <Flex height={25} />
      <CopyURLField url={window.location.href} />
      {status === 'OfferCreated' && !hasSeller && (
        <Button disabled={loading} type="submit" width={1} onClick={onSellerInitialize}>
          {!loading ? 'Become Seller' : <Loader color="white" />}
        </Button>
      )}
      {status === 'OfferCreated' && hasSeller && (
        <Button disabled type="submit" width={1}>
          Waiting for the Buyer...
        </Button>
      )}
      {status === 'PaymentConfirmed' && (
        <Button disabled={loading} type="submit" width={1} onClick={onSellerConfirm}>
          {!loading ? 'Confirm Fulfillment' : <Loader color="white" />}
        </Button>
      )}
      {status === 'SellerConfirmed' && (
        <Button disabled type="submit" width={1}>
          Waiting for the Buyer...
        </Button>
      )}
      {status === 'BuyerConfirmed' && (
        <Button disabled={loading} type="submit" width={1} onClick={onDisburse}>
          {!loading ? 'Disburse Payment' : <Loader color="white" />}
        </Button>
      )}
      {status === 'OfferFinalized' && (
        <Button disabled type="submit" width={1}>
          Offer Finalized
        </Button>
      )}
    </Card>
  );
}
