export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

let _networkName;

export const setNetwork = networkName => {
  _networkName = networkName;
};

export const getTokens = () => {
  let tokens = {
    ETH: 'ETH',
  };

  if (_networkName === 'Private') {
    tokens = {
      ...tokens,
      // dummy token on localhost
      TST: '0xE53cf66484DbBEb62Bd2DFE9f16B254f53f33eA2',
    };
  }

  if (_networkName === 'Main') {
    tokens = {
      ...tokens,
      USDT: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    };
  }

  return tokens;
};
