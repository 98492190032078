import React from 'react';
import { Text, Form, Textarea, Button, Loader, Select, Flex } from 'rimble-ui';
import { RadioGroup, Dialog, Pane } from 'evergreen-ui';
import useForm from 'react-hook-form';
import { getTokens } from '../constants/addresses';

const messages = {
  title: {
    required: 'Title is required',
  },
  amount: {
    pattern: 'This is not a valid amount.',
  },
};

const ErrorMessage = ({ errors, name }) => {
  if (!errors[name]) return null;
  return <Text color="red">{messages[name][errors[name].type]}</Text>;
};

export default function CreateForm(props) {
  const { onSubmit, show, loading, onClose, role, onRoleChange } = props;
  const { register, handleSubmit, errors } = useForm();
  const tokens = getTokens();

  return (
    <Dialog isShown={show} onCloseComplete={onClose} hasHeader={false} hasFooter={false}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup
          size={16}
          label="Your Role"
          value={role}
          onChange={onRoleChange}
          options={[
            { label: 'Buyer - I am buying good/service', value: 'buyer' },
            { label: 'Seller - I am selling good/service', value: 'seller' },
          ]}
          name="role"
        />
        <Form.Field label="Title" width={1}>
          <Pane required width="100%">
            <Form.Input
              type="text"
              required
              width={1}
              name="title"
              ref={register({ required: true })}
              placeholder="Tell us about the product/service"
            />
            <ErrorMessage errors={errors} name="title" />
          </Pane>
        </Form.Field>
        <Form.Field label="Description" width={1}>
          <Pane width="100%">
            <Textarea
              rows={4}
              width={1}
              name="description"
              ref={register({ required: false })}
              placeholder="Provide a detailed description for the product/service"
            />
            <ErrorMessage errors={errors} name="description" />
          </Pane>
        </Form.Field>
        <Pane width="100%" marginBottom={20}>
          <Text fontWeight={'bold'} fontSize={1}>
            Amount
          </Text>
          <Flex flexDirection="row">
            <Select
              required
              name="token"
              marginTop="5px"
              options={Object.keys(tokens).map(token => ({
                value: token,
                label: token,
              }))}
              ref={register({ required: true })}
            />
            <Form.Input type="number" step="any" required name="amount" ref={register({ pattern: /\d+/ })} />
          </Flex>
          <ErrorMessage errors={errors} name="amount" />
        </Pane>
        <Button disabled={loading} type="submit" width={1} mt={3}>
          {!loading ? 'Confirm' : <Loader color="white" />}
        </Button>
      </Form>
    </Dialog>
  );
}
