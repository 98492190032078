import React from 'react';
import { Field, Input } from 'rimble-ui';
import useClipboard from 'react-use-clipboard';

export default function CopyURLField(props) {
  const { url } = props;
  const [isCopied, setCopied] = useClipboard(url, {
    successDuration: 1000,
  });
  return (
    <Field label={!isCopied ? `Click to Copy URL` : `Click to Copy URL (copied!)`} width={1}>
      <Input type="text" value={url} required readOnly onClick={setCopied} />
    </Field>
  );
}
