import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Button } from 'rimble-ui';
import NetworkIndicator from '@rimble/network-indicator';

export default function HeaderNav(props) {
  const { web3Context } = props;
  const { networkId, accounts } = web3Context;

  const requestAuth = async () => {
    try {
      await web3Context.requestAuth();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Navbar bg="light">
      <Container>
        <Navbar.Brand href="/">Dxcrow</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <NetworkIndicator currentNetwork={networkId} />
            {(!accounts || accounts.length === 0) && (
              <Button size="small" onClick={() => requestAuth()} mt={1}>
                Connect Your Wallet
              </Button>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
