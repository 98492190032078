import React, { useState, useEffect } from 'react';
import { Flex, Card, Heading } from 'rimble-ui';
import { useParams } from 'react-router-dom';
import ProgressStep from './ProgressStep';
import SellerView from './SellerView';
import BuyerView from './BuyerView';
import { NULL_ADDRESS, getTokens } from '../constants/addresses';

const DxcrowJSON = require('../../../contracts/Dxcrow.sol');
const ERC20JSON = require('@openzeppelin/contracts/token/ERC20/ERC20.sol');

const getSteps = escrow => [
  {
    label: 'Offer Created',
    status: 'OfferCreated',
    completed: true,
  },
  {
    label: 'Payment Confirmed',
    status: 'PaymentConfirmed',
    completed: parseInt(escrow.balance) === parseInt(escrow.targetAmount),
  },
  {
    label: 'Seller Confirmed',
    status: 'SellerConfirmed',
    completed: escrow.sellerConfirmed,
  },
  {
    label: 'Buyer Confirmed',
    status: 'BuyerConfirmed',
    completed: escrow.buyerConfirmed,
  },
  {
    label: 'Offer Finalized',
    status: 'OfferFinalized',
    completed: escrow.finalized,
  },
];

const getStatus = steps => {
  const latest = steps.findIndex(step => !step.completed);
  return latest > -1 ? steps[latest - 1].status : 'OfferFinalized';
};

export default function ViewForm(props) {
  const { ipfs, web3Context } = props;
  const { hash } = useParams();
  const tokens = getTokens();
  const [manifest, setManifest] = useState(null);
  const [escrow, setEscrow] = useState(null);
  const [contract, setContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const steps = escrow ? getSteps(escrow) : [];
  const status = escrow ? getStatus(steps) : null;
  const isSeller = escrow ? escrow.seller === web3Context.accounts[0] : false;
  const hasSeller = escrow ? escrow.seller !== NULL_ADDRESS : false;
  const isBuyer = escrow ? escrow.buyer === web3Context.accounts[0] : false;
  const hasBuyer = escrow ? escrow.buyer !== NULL_ADDRESS : false;

  useEffect(() => {
    const getManifest = async () => {
      setManifest(await ipfs.get(hash));
      const deployedNetwork = DxcrowJSON.networks[web3Context.networkId];
      const contract = new web3Context.lib.eth.Contract(DxcrowJSON.abi, deployedNetwork && deployedNetwork.address);
      setContract(contract);
      setEscrow(await contract.methods.getEscrow(hash).call());
    };
    getManifest();
  }, [hash, ipfs, web3Context.lib.eth.Contract, web3Context.networkId]);

  useEffect(() => {
    if (manifest && manifest.tokenAddress !== tokens.ETH) {
      const tokenContract = new web3Context.lib.eth.Contract(ERC20JSON.abi, manifest.tokenAddress);
      setTokenContract(tokenContract);
    }
  }, [manifest, tokens.ETH, web3Context.lib.eth.Contract, web3Context.networkId]);

  if (!manifest || !escrow) {
    return <div>Loading...</div>;
  }

  const onSellerInitialize = async () => {
    setLoading(true);
    const digestData = {
      ...manifest,
      sellerInitialized: Math.round(new Date().getTime() / 1000),
    };
    const digest = await ipfs.add(digestData);
    await contract.methods.becomeSeller(hash, digest).send({ from: web3Context.accounts[0] });
    document.location.reload(true);
    setLoading(false);
  };

  const onSellerConfirm = async () => {
    setLoading(true);
    const digestData = {
      ...manifest,
      sellerConfirmed: Math.round(new Date().getTime() / 1000),
    };
    const digest = await ipfs.add(digestData);
    await contract.methods.confirmSeller(hash, digest).send({ from: web3Context.accounts[0] });
    document.location.reload(true);
    setLoading(false);
  };

  const onBuyerConfirm = async () => {
    setLoading(true);
    const digestData = {
      ...manifest,
      buyerConfirmed: Math.round(new Date().getTime() / 1000),
    };
    const digest = await ipfs.add(digestData);
    await contract.methods.confirmBuyer(hash, digest).send({ from: web3Context.accounts[0] });
    document.location.reload(true);
    setLoading(false);
  };

  const onSendPayment = async () => {
    setLoading(true);
    const from = web3Context.accounts[0];
    const digestData = {
      ...manifest,
      paymentSent: Math.round(new Date().getTime() / 1000),
    };
    const digest = await ipfs.add(digestData);
    if (manifest.tokenAddress === tokens.ETH) {
      await contract.methods
        .makePayment(hash, digest)
        .send({ from, value: web3Context.lib.utils.toWei(manifest.amount, 'ether') });
    } else {
      const approval = await tokenContract.methods.approve(contract._address, manifest.amount).send({ from });
      if (approval.status) {
        await contract.methods.makeTokenPayment(hash, manifest.amount, digest).send({ from });
      }
    }
    document.location.reload(true);
    setLoading(false);
  };

  const onDisburse = async () => {
    setLoading(true);
    const digestData = {
      ...manifest,
      paymentDisbursed: Math.round(new Date().getTime() / 1000),
    };
    const digest = await ipfs.add(digestData);
    await contract.methods.disbursePayment(hash, digest).send({ from: web3Context.accounts[0] });
    document.location.reload(true);
    setLoading(false);
  };

  return (
    <Flex flexDirection="column" padding={25} marginTop={25} alignItems="center" width="100%">
      {(isSeller || isBuyer || (!hasSeller || !hasBuyer)) && <ProgressStep steps={steps} />}
      {(isSeller || !hasSeller) && !isBuyer && (
        <SellerView
          status={status}
          manifest={manifest}
          escrow={escrow}
          onSellerInitialize={onSellerInitialize}
          onSellerConfirm={onSellerConfirm}
          onDisburse={onDisburse}
          loading={loading}
          hasBuyer={hasBuyer}
          hasSeller={hasSeller}
        />
      )}
      {(isBuyer || !hasBuyer) && !isSeller && (
        <BuyerView
          status={status}
          manifest={manifest}
          escrow={escrow}
          onBuyerConfirm={onBuyerConfirm}
          onSendPayment={onSendPayment}
          loading={loading}
          hasBuyer={hasBuyer}
          hasSeller={hasSeller}
        />
      )}
      {!isSeller && !isBuyer && hasSeller && hasBuyer && (
        <Card>
          <Heading>You don't have permission to view this page.</Heading>
        </Card>
      )}
    </Flex>
  );
}
