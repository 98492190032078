import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useWeb3 } from '@openzeppelin/network/react';
import HeaderNav from './components/HeaderNav';
import Home from './components/Home';
import CreateForm from './components/CreateForm';
import ViewForm from './components/ViewForm';
import { NULL_ADDRESS, setNetwork, getTokens } from './constants/addresses';
import getIPFS from './utils/ipfs';

const WEB3_FALLBACK = process.env.REACT_APP_WEB3_FALLBACK;
const DxcrowJSON = require('../../contracts/Dxcrow.sol');

function App() {
  const context = useWeb3(WEB3_FALLBACK);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState('buyer');
  const ipfs = getIPFS();
  const tokens = getTokens();

  useEffect(() => {
    const bootstrap = async () => {
      const deployedNetwork = DxcrowJSON.networks[context.networkId];
      setContract(new context.lib.eth.Contract(DxcrowJSON.abi, deployedNetwork && deployedNetwork.address));
      setNetwork(context.networkName);
    };
    bootstrap();
  }, [context.networkId, context.networkName, context.lib.eth.Contract]);

  const onSubmit = async data => {
    setLoading(true);
    const manifestData = {
      ...data,
      buyer: role === 'buyer' ? context.accounts[0] : NULL_ADDRESS,
      seller: role === 'seller' ? context.accounts[0] : NULL_ADDRESS,
      timestamp: Math.round(new Date().getTime() / 1000),
      tokenAddress: tokens[data.token],
    };
    try {
      const manifest = await ipfs.add(manifestData);
      if (manifestData.tokenAddress === tokens.ETH) {
        await contract.methods
          .startEscrow(
            manifestData.buyer,
            manifestData.seller,
            context.lib.utils.toWei(manifestData.amount, 'ether'),
            manifest,
            manifest,
          )
          .send({ from: context.accounts[0] });
      } else {
        await contract.methods
          .startTokenEscrow(
            manifestData.buyer,
            manifestData.seller,
            manifestData.amount,
            manifest,
            manifest,
            manifestData.tokenAddress,
          )
          .send({ from: context.accounts[0] });
      }
      window.location.replace(`/b/${manifest}`);
      setLoading(false);
    } catch (e) {
      console.log(e);
      alert(e.message);
      setLoading(false);
    }
  };

  const onCTAClick = role => () => {
    setRole(role);
    setShowCreateForm(true);
  };

  if (!context.lib || !contract) {
    return <div>Loading Web3, accounts, and contract...</div>;
  }

  return (
    <div>
      <HeaderNav web3Context={context} />
      <CreateForm
        show={showCreateForm}
        role={role}
        loading={loading}
        onRoleChange={setRole}
        onClose={() => setShowCreateForm(false)}
        onSubmit={onSubmit}
      />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home onBuyer={onCTAClick('buyer')} onSeller={onCTAClick('seller')} />
          </Route>
          <Route path="/b/:hash">
            <ViewForm ipfs={ipfs} contract={contract} web3Context={context} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
