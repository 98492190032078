import React from 'react';
import { Jumbotron, Container, Button, CardDeck, Card } from 'react-bootstrap';

export default function Home(props) {
  return (
    <>
      <Jumbotron fluid>
        <Container>
          <h1>Decentralized Escrow</h1>
          <p>Secure way to buy and sell across borders, free of charge.</p>
          <p className="mt-3">
            <Button onClick={props.onSeller} className="mr-3" variant="outline-primary" size="lg">
              Sell Something
            </Button>
            <Button onClick={props.onBuyer} variant="outline-secondary" size="lg">
              Buy Something
            </Button>
          </p>
          <p>Powered by Ethereum & IPFS.</p>
        </Container>
      </Jumbotron>
      <Container>
        <CardDeck>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Decentralized</Card.Title>
              <Card.Text>Powered by Smart Contracts on Ethereum</Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Borderless</Card.Title>
              <Card.Text>Accept ETH and ERC20 tokens</Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Zero Fees</Card.Title>
              <Card.Text>Pay only gas!</Card.Text>
            </Card.Body>
          </Card>
        </CardDeck>
        <CardDeck className="mt-4">
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Anonymous</Card.Title>
              <Card.Text>We don't store any personal information</Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Secure</Card.Title>
              <Card.Text>Protections for both buyer and seller</Card.Text>
            </Card.Body>
          </Card>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Dispute Resolution</Card.Title>
              <Card.Text>Arbitration made easy with consensus-based algorithm</Card.Text>
            </Card.Body>
          </Card>
        </CardDeck>
      </Container>
      <Container>
        <footer className="pt-4 my-md-5 pt-md-5 border-top">
          <div className="row">
            <div className="col-12 col-md">
              <small className="d-block mb-3 text-muted">&copy; 2020</small>
            </div>
          </div>
        </footer>
      </Container>
    </>
  );
}
